// eslint-disable-next-line import/no-cycle
import requestService from "../requestService";

const prefix = '/streams';

export default {
  async getStreams(params) {
    const response = await requestService.get(`${prefix}`,
        {...params});
    return response?.data?.data;
  },
  async addStream(payload) {
    const response = await requestService.post(`${prefix}`, payload);
    return response?.data?.data;
  },
  async updateStream(streamId, payload) {
    const response = await requestService.post(`${prefix}/${streamId}`, payload);
    return response?.data?.data;
  },
  async deleteStream(streamId, payload) {
    const response = await requestService.delete(`${prefix}/${streamId}`, payload);
    return response?.data?.data;
  }
};
