<template>
  <v-dialog
      v-model="visibility"
      max-width="800"
      content-class="cross__dialog"
      :persistent="changed"
      :transition="$dialogTransition"
      @click:outside="showConfirmDialog"
      @keydown.enter="onCreate()"
      scrollable
  >
    <v-card>
      <v-fade-transition>
        <v-progress-linear
            v-if="loading"
            absolute
            top
            indeterminate
            color="primary"
        ></v-progress-linear>
      </v-fade-transition>
      <v-slide-x-transition>
        <div class="cross__inner" v-if="!loading">
          <v-btn fab small elevation="0" @click="showConfirmDialog">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
      </v-slide-x-transition>
      <v-card-title>
        {{
          type == "create" ? $t("form.create_an_order") : $t("home.create_stream")
        }}
      </v-card-title>
      <v-card-text class="pb-5 pt-4">
        <div>
          <v-row>
            <v-col cols="12">
              <v-text-field
                  outlined
                  dense
                  color="primary"
                  v-model="inputOrder.client_name"
                  :label="$t('form.client_name')"
                  :maxlength="25"
                  hide-details="auto"
                  :error-messages="nameErrors"
                  @keypress="validationForTitle($event)"
                  @paste="validationForTitle($event)"
              />
            </v-col>
            <v-col cols="12">
              <v-text-field
                  outlined
                  dense
                  color="primary"
                  v-model="inputOrder.sale_price"
                  :label="$t('offersCatalog.price')"
                  :maxlength="25"
                  hide-details="auto"
                  :error-messages="priceErrors"
                  @keypress="validationForPrice"
                  @paste="validationForPrice"
              />
            </v-col>
            <v-col cols="12">
              <v-autocomplete
                  outlined
                  dense
                  color="primary"
                  :items="geoList"
                  v-model="inputOrder.geo_id"
                  :item-value="'id'"
                  :item-text="'name'"
                  :label="$t('offersCatalog.geo')"
                  hide-details="auto"
                  :error-messages="geoErrors"
                  @input="$v.inputOrder.geo_id.$touch()"
                  @blur="$v.inputOrder.geo_id.$touch()"
              />
            </v-col>
            <v-col cols="12">
              <v-autocomplete
                  outlined
                  dense
                  color="primary"
                  :items="streamsList"
                  v-model="inputOrder.stream_id"
                  :item-value="'id'"
                  :item-text="'name'"
                  :label="$t('home.streams')"
                  hide-details="auto"
                  :error-messages="streamsErrors"
                  @input="$v.inputOrder.stream_id.$touch()"
                  @blur="$v.inputOrder.stream_id.$touch()"
              />
            </v-col>
            <v-col cols="12">
              <v-text-field
                :label="$t('form.phone_number')"
                dense
                outlined
                color="primary"
                hide-details="auto"
                class="auth-input text-center-vertical"
                prefix="+38"
                v-on:keypress="validationForPhoneNumber($event)"
                @paste="validationForPhoneNumber($event)"
                v-model="inputOrder.client_phone"
                :maxLength="10"
                :error-messages="phoneErrors"
              />
            </v-col>

          </v-row>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-row no-gutters justify="space-between">
          <v-col cols="6" class="pr-1">
            <v-btn
                dark
                block
                class="br-5 text-transform-none"
                @click="showConfirmDialog"
            >{{ $t("btn.cancel") }}</v-btn
            >
          </v-col>
          <v-col cols="6" class="pl-1">
            <v-btn
                v-if="type === 'edit'"
                block
                class="btn-orange-bg text-transform-none br-5"
                :disabled="!changed"
                :loading="loading"
                @click="onUpdate()"
            >{{ $t("btn.update") }}</v-btn
            >
            <v-btn
                v-if="type === 'create'"
                block
                class="btn-orange-bg text-transform-none br-5"
                :disabled="!changed"
                :loading="loading"
                @click="onCreate()"
            >{{ $t("btn.create") }}</v-btn
            >
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
    <ConfirmLeftDialog
        :visible="visibleConfirm"
        @close="visibleConfirm = false"
        @delete="onClose"
    />
  </v-dialog>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import {validationForPhoneNumber, validationForTitle} from "@/mixins/helpers";
import ConfirmLeftDialog from "@/components/dialog/ConfirmLeftDialog.vue";
import dialogMixin from "@/mixins/dialogMixin";
import notifications from "@/mixins/notifications";
import tImgMixin from "@/mixins/tImgMixin";
import offersService from "@/services/request/offers/offersService";
import _ from "lodash";
import streamsService from "@/services/request/streams/streamsService";
import phoneMask from "@/validators/phoneMask";
import geoService from "@/services/request/geo/geoService";
import ordersService from "@/services/request/orders/ordersService";
import { validationForPrice } from "@/mixins/helpers";

export default {
  data: () => ({
    loading: false,
    changed: false,
    visibleConfirm: false,
    inputOrder: {},
    copyOrder: {},
    order: {},
    geoList: [],
    streamsList: [],
  }),
  mixins: [dialogMixin, validationMixin, notifications, tImgMixin],
  validations: {
    inputOrder: {
      client_name: { required },
      sale_price: {
        required,
        validPrice: (value) => /^\d*\.?\d{0,2}$/.test(value)
      },
      geo_id: { required },
      client_phone: { required, phoneMask },
      stream_id: { required },
    },
  },
  components: {
    ConfirmLeftDialog,
  },
  props: {
    type: {
      required: true,
    },
  },
  async mounted() {
    this.onMounted();
  },
  methods: {
    validationForPhoneNumber,
    validationForTitle,
    validationForPrice,
    async onMounted() {
      this.loading = true;
      this.fetchStreams();
      this.getGeo();
      this.order = {
        client_name: "",
        sale_price: "",
        geo_id: "",
        client_phone: "",
        stream_id: ""
      };
      this.inputOrder = _.cloneDeep(this.order);
      this.copyOrder = _.cloneDeep(this.order);
      this.loading = false;
    },
    onClose() {
      this.$v.$reset();
      this.$emit("close");
    },
    showConfirmDialog() {
      this.changed ? (this.visibleConfirm = true) : this.onClose();
    },
    async fetchStreams() {
      try {
        this.streamsList = await streamsService.getStreams();
      } catch (e) {
        console.log(e);
      }
    },
    async getGeo() {
      try {
        this.loading = true;
        this.geoList = await geoService.getGeo();
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false;
      }
    },
    async onCreate() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.loading = true;
        try {
          const formData = new FormData();
          const changedData = this.$getChangedData(this.inputOrder, this.copyOrder);

          changedData.forEach((data) => {
            formData.append(data.field, data.value);
          });

          const createdOrder = await ordersService.addOrder(formData);
          this.$emit("close");
          this.$v.$reset();
          this.setSuccessNotification(
              this.$t("home.successfully_created_order")
          );
          this.$emit('orderCreated', createdOrder);
        } catch (e) {
          this.setErrorNotification(e.message);
        } finally {
          this.loading = false;
        }
      }
    },
    async onUpdate() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.loading = true;
        try {
          const formData = new FormData();
          formData.append("_method", 'patch');
          const changedData = this.$getChangedData(this.inputOrder, this.copyOrder);
          changedData.forEach((data) => {
            formData.append(data.field, data.value);
          });
          await offersService.updateOffer(
              this.inputOrder.id,
              formData
          );
          this.$v.$reset();
          this.setSuccessNotification(
              this.$t("home.successfully_updated_stream")
          );
          this.visibility = false;
        } catch (e) {
          this.setErrorNotification(e.message);
        } finally {
          this.loading = false;
        }
      }
    },
  },
  computed: {
    nameErrors() {
      const errors = [];
      if (!this.$v.inputOrder.client_name.$dirty) {
        return errors;
      }
      !this.$v.inputOrder.client_name.required &&
      errors.push(this.$t("form.errors.NameRequired"));
      return errors;
    },
    priceErrors() {
      const errors = [];
      if (!this.$v.inputOrder.sale_price.$dirty) {
        return errors;
      }
      if (!this.$v.inputOrder.sale_price.required) {
        errors.push(this.$t("form.errors.PriceRequired"));
      }
      if (!/^\d*\.?\d{0,2}$/.test(this.inputOrder.sale_price)) {
        errors.push(this.$t("form.errors.InvalidPriceFormat"));
      }
      return errors;
    },
    geoErrors() {
      const errors = [];
      if (!this.$v.inputOrder.geo_id.$dirty) return errors;
        !this.$v.inputOrder.geo_id.required && errors.push(this.$t("form.errors.geoRequired"));
        return errors;
      },

    phoneErrors() {
      const errors = [];
      if (!this.$v.inputOrder.client_phone.$dirty) {
        return errors;
      }
      if (!this.$v.inputOrder.client_phone.required) {
        errors.push(this.$t("form.errors.PhoneNumberRequired"));
      }
      !this.$v.inputOrder.client_phone.phoneMask && errors.push(this.$t("form.errors.PhoneNumberMustBeLike"));
      return errors;
    },
    streamsErrors() {
  const errors = [];
  if (!this.$v.inputOrder.stream_id.$dirty) return errors;
  !this.$v.inputOrder.stream_id.required && errors.push(this.$t("form.errors.streamRequired"));
  return errors;
},

  },
  watch: {
    inputOrder: {
      deep: true,
      handler() {
        this.changed = !_.isEqual(this.copyOrder, this.inputOrder);
      },
    },
  },
};
</script>

<style>
.auth-input .v-input__control {
  display: flex;
  align-items: flex-start; /* Вирівнювання по лівому краю */
}

</style>
