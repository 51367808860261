// eslint-disable-next-line import/no-cycle
import requestService from "../requestService";

const prefix = '/orders';

export default {
  async getOrders(params) {
    const response = await requestService.get(`${prefix}`,
        {
      ...params,
    });
    return response?.data?.data;
  },
  async addOrder(payload) {
    const response = await requestService.post(`${prefix}`, payload, {}, false);
    return response?.data?.data;
  },
  async updateOrder(orderId, payload) {
    const response = await requestService.post(`${prefix}/${orderId}`, payload);
    return response?.data?.data;
  },
  async deleteOrder(orderId, payload) {
    const response = await requestService.delete(`${prefix}/${orderId}`, payload);
    return response?.data?.data;
  }
};
